import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import PCHome from '@/views/pc/home.vue'
import MHome from '@/views/m/home.vue'

import { IsPC as IsPC } from '@/utils/util'

Vue.use(VueRouter)

// if(window.location.host == 'zzcl.kjcxchina.com'){
//   indexUrl = '../views/pc/index.vue'
// }
// if(window.location.host=='jt.zzcl.kjcxchina.com'){
//   indexUrl = '../views/pc/group_index.vue'
//   // @/views/pc/group_index.vue
// }

const routes_pc = [
  {
    path: '*',
    redirect: '/'
  },

  {
    path: '/',
    name: 'PCHome',
    component: PCHome,
    children: [
      //首页
      {
        path: '/',
        name: 'Index',
        component: () => import('@/views/pc/index.vue'),
        meta:{
          topState:true
        }
      }
    ]
  }
]
const routes_m = [
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/',
    name: 'mHome',
    component: MHome,
    children: [
      //首页
      {
        path: '/',
        name: 'Index',
        component: () => import('@/views/m/index.vue'),
        meta:{
        }
      }, {
        path: '/form',
        name: 'form',
        component: () => import('@/views/m/form.vue'),
        meta:{
        }
      },{
        path:'/preface',
        name:'preface',
        component: () => import('@/views/m/preface.vue'),
        meta:{}
      },{
        path:'/success',
        name:'success',
        component: () => import('@/views/m/success.vue'),
        meta:{}
      }
    ]
  },
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  // routes: IsPC() ? routes_pc : routes_m
  routes: routes_m 
})

export default router
